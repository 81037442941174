.p6c612j canvas{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;touch-action:none;width:85.333vw;height:28.267vw;}@media(min-width:600px){.p6c612j canvas{width:100.000vw;height:33.333vw;}}
.ma7lxhi{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;z-index:7;position:absolute;top:0;}@media(min-width:600px){.ma7lxhi{z-index:10;}}@media(min-width:1024px){.ma7lxhi{z-index:10;}}
.t1a72jhn{height:100vh;width:100vw;}
.t49cl7c{background:orange;}

.h1d80g6r{position:relative;top:0;width:100%;z-index:11;background-color:#000;padding-left:4.267vw;padding-right:4.267vw;}@media(min-width:600px){.h1d80g6r{padding-left:3.906vw;padding-right:3.906vw;}}@media(min-width:1024px){.h1d80g6r{padding-left:3.125vw;padding-right:3.125vw;}}
.ojinu9x{position:absolute;height:100%;width:100%;background-color:black;z-index:1;top:0;opacity:1;}
.o1q3r7fs{height:100%;width:100%;display:grid;place-content:center;grid-template-areas:'body';overflow:hidden;}.o1q3r7fs::before,.o1q3r7fs::after{content:'';grid-area:body;width:150vmax;height:150vmax;-webkit-animation-timing-function:linear;animation-timing-function:linear;-webkit-animation-iteration-count:infinite;animation-iteration-count:infinite;}.o1q3r7fs::before{-webkit-animation-name:rotate-o1q3r7fs;animation-name:rotate-o1q3r7fs;-webkit-animation-duration:40s;animation-duration:40s;background:linear-gradient(to left,#FFE3D6,#FFE3D6,#FED570,#FF6349,#DB4532,#DB4532);}.o1q3r7fs::after{-webkit-animation-name:rotate-o1q3r7fs,fade-o1q3r7fs;animation-name:rotate-o1q3r7fs,fade-o1q3r7fs;-webkit-animation-duration:calc(40s / 2),40s;animation-duration:calc(40s / 2),40s;background:linear-gradient(to right,#FFE3D6,#FFE3D6,#FED570,#FF6349,#DB4532,#DB4532);}@-webkit-keyframes rotate-o1q3r7fs{to{-webkit-transform:rotate(1turn);-ms-transform:rotate(1turn);transform:rotate(1turn);}}@keyframes rotate-o1q3r7fs{to{-webkit-transform:rotate(1turn);-ms-transform:rotate(1turn);transform:rotate(1turn);}}@-webkit-keyframes fade-o1q3r7fs{50%{opacity:0;}}@keyframes fade-o1q3r7fs{50%{opacity:0;}}
.l18eue8{width:100%;height:100%;z-index:5;background:#000;position:absolute;opacity:var(--l18eue8-0);top:0;}.l18eue8 .loader-circle-8{margin-top:-140px;}
.vtziwmz{position:absolute;z-index:7;box-shadow:inset 0px 0px 0px 1px rgb(255 255 255 / 20%);opacity:0;object-fit:cover;}
.besumq8{display:block;}@media(min-width:600px){.besumq8{display:none;}}@media(min-width:1024px){.besumq8{display:none;}}
.f1qwnrzx{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:flex-end;-webkit-box-align:flex-end;-ms-flex-align:flex-end;align-items:flex-end;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;position:absolute;visibility:hidden;z-index:5;width:100%;height:100vh;height:100svh;}
.f7f5yhf{background:#000;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;position:relative;z-index:10;width:100%;font-weight:400;font-size:3.733vw;height:58.667vw;}@media(min-width:600px){.f7f5yhf{font-weight:500;font-size:3.125vw;height:37.240vw;}}@media(min-width:1024px){.f7f5yhf{font-weight:500;font-size:1.250vw;height:14.896vw;}}
