html {
  height: -webkit-fill-available;
}
html, body {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
body {
  /* min-height: 667px; */
  min-height: -webkit-fill-available;
  background-color: rgb(0 0 0);
  color: rgb(255 255 255);
}

body, button {
  font-family: "CTCSans", Calibri, Tahoma;
  font-weight: 400;
}

button {
  padding: 0;
}

.no-scroll {
  overflow: hidden;
}

a {
  color: rgb(255 255 255);
  text-decoration: none;
}

ul {
  margin: 0;
}

body.windows::-webkit-scrollbar {
  width: 2px;
}
body.windows::-webkit-scrollbar-track {
  background: transparent;
}
body.windows::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}

.split-parent {
  overflow: hidden;
  display: inline-grid !important;
}
.split-child {
  display: inline-block !important;
}
